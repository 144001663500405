
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript.js';
  
  

  
  var importedApp = {};


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://e9745c9ce468483fb5e92dc23a5a395b@sentry.wixpress.com/3337',
      id: 'e9745c9ce468483fb5e92dc23a5a395b',
      projectName: 'checkout-app',
      teamName: 'jurijm-checkout-test',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/d4e13f6a2ec10edf/packages/checkout-app/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/d4e13f6a2ec10edf/packages/checkout-app/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "checkout-app",
    biConfig: null,
    appName: "jurijm-checkout",
    appDefinitionId: "0aa15524-32c3-4458-924e-20e0ce1da501",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scopes":["test"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          isUsingAppSettings: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/d4e13f6a2ec10edf/packages/checkout-app/src/components/Checkout/controller.ts",
          appName: "jurijm-checkout",
          appDefinitionId: "0aa15524-32c3-4458-924e-20e0ce1da501",
          projectName: "checkout-app",
          componentName: "Checkout",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "c53db4f8-fc3c-495b-bc33-8c705c6088a1" }],
    false);

    export const createControllers = _createControllers
